import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step, { StepProps } from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  CircularProgress,
  StepConnector,
  stepConnectorClasses,
  StepIcon,
} from "@mui/material";
import styled from "@emotion/styled";
import StepperContainer from "./StepperContainer";
import { useForm } from "react-hook-form";
import "./stepper.scss";
import HoldOn from "./HoldOn";
import { Cancel } from "@mui/icons-material";
import { css } from "@emotion/react";
// import back from '../../assests/images/back.svg'
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
const QontoConnector = styled(StepConnector)(({ theme }: any) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 10px)",
    right: "calc(50% + 10px)",
    zIndex: "-1",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#C8172F",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#C8172F",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#FFEBEF",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));
const StyledStepper = styled(Stepper)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));
interface StyledStepProps extends StepProps {
  error?: boolean; // Add the error prop as optional
}

const StyledStep = styled(Step)<StyledStepProps>(({ theme, error }) =>
  css({
    padding: "0 !important",
    ".MuiSvgIcon-root": {
      borderRadius: "30px",
      border: "1px solid #C5C6C7",
      background: "#F5F6F7",
      color: "transparent",
      "&.Mui-active": {
        borderRadius: "30px",
        border: "1px solid #C8172F",
        background: "#FFEBEF",
        color: "transparent",
        ".MuiStepIcon-text": {
          fill: "#C8172F",
        },
      },
      "&.Mui-completed": {
        color: "#46C65A",
        border: "0",
      },
      ".MuiStepIcon-text": {
        fill: "#7D7E7F",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "normal",
        letterSpacing: "0.12px",
      },
    },
    ".MuiStepLabel-labelContainer": {
      ".MuiStepLabel-label": {
        color: "#7D7E7F",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        letterSpacing: "0.12px",
        marginTop: "4px",
        ".points_count": {
          display: "block",
          color: "#7D7E7F",
          textAlign: "center",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "18px",
          letterSpacing: "0.12px",
          ".error": {
            color: "#C8172F",
          },
        },
        ".error": {
          color: "#C8172F",
        },
      },
    },
    // Apply conditional styles based on the error prop
    ...(error && {
      ".MuiSvgIcon-root": {
        color: "#C8172F",
      },
      ".MuiStepLabel-labelContainer .MuiStepLabel-label": {
        color: "#C8172F", // Change label color if there's an error
      },
    }),
  })
);

const StyledStepLabel = styled(StepLabel)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    // Custom styles for step labels in mobile
    fontSize: "1rem",
  },
}));

export default function Feedback({ questData }: any) {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [showConfirmation, setShowConfirmation] =
    React.useState<boolean>(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [submitBtnName, setSubmitBtnName] = React.useState<string>(t('taskdetaitls.educational.quest_submit_btn'));
  const [answerData, setAnswerData] = React.useState<
    Array<{
      task_id: string;
      question_id: string;
      answer: string;
      no_of_attempts: string;
      is_correct: boolean;
    }>
  >([]);
  const authContext = React.useContext(AuthContext);

  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const encryptedTaskId = window.btoa(questData.data.task_id.toString());

  const steps =
    questData?.data?.subtasks?.map((subtask: any) => ({
      qno: subtask?.question_no,
      qid: subtask?.question_id,
      points: subtask?.points,
      quest: subtask?.question,
      opts: subtask?.options.map((option: any) => option.option_value),
      ans: subtask?.options.find((option: any) => option.correct_option === "1")
        ?.option_value,
      hint: subtask?.hint?.content_type,
    })) || [];

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = async (ansData: any) => {
    await storeAnswers(ansData).then((res)=>{
      setLoading(false);
      if (activeStep+1 !== steps.length) {
        setSubmitBtnName(t('taskdetaitls.educational.quest_submit_btn'));
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }
  
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      } else {
        navigate(`/points/${encryptedTaskId}`);
      }
      setIsDirty(false);
    }).catch((err)=>{
      setLoading(false);
    });
  };

  const storeAnswers = async (answerData: any) => {
    setLoading(true);
    return new Promise(async(resolve,reject)=>{
      if (answerData.length > 0 && answerData.length === steps.length) {
        if (authContext) {
          const { token } = authContext;
          try {
            const response = await fetch(
              `${process.env.REACT_APP_BASE_URL}/answer/store-answer`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                'Accept-Language':i18n.language,
                },
                body: JSON.stringify(answerData),
              }
            );
  
            if (!response.ok) {
              throw new Error("Failed to store answers");
            }
            setLoading(false);
  
            const result = await response.json();
            resolve(result)
            console.log("Answers stored successfully:", result);
          } catch (error) {
            setLoading(false);
            console.error("Error submitting answer", error);
            reject("Error submitting answer");
          }
        }else{
          setLoading(false);
          reject("Error submitting answer");
        }
      }
      resolve("not needed")
    })
  };

  const handleChange = (event:any,index:any,question_id:any) => {
    const data=[...answerData]
    if(data[index]){
      data[index]={...data[index],answer:event.target.value}
    }else{

      data.push({
        task_id: questData.data.task_id,
        question_id: question_id,
        no_of_attempts: '1',
        is_correct: true,
        answer:event.target.value
      })
    }
    setAnswerData(data)
  };
  return (
    <Box sx={{ width: "100%", 
    // height: !startquiz ? '450px' : '500px', overflowY: "auto"
     }}>
      {/* <div className='head'>
        <Button onClick={!startquiz ? () => navigate('/tasks') : () => handleBack(activeStep)}><img src={back} /></Button>
        <Typography variant="body1">{i18n.language==="hi"?questData?.data?.task_desc_hi:questData?.data?.task_desc_en}</Typography>
      </div> */}
      
        <>
          <StyledStepper
            connector={<QontoConnector />}
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map(({ qno, points }: any, index: any) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
                error?: boolean;
                icon?: any;
                color?: string;
              } = {
                color: "#C8172F",
              };
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <StyledStep
                  error={false}
                  key={qno}
                  {...stepProps}
                >
                  <StyledStepLabel {...labelProps}>
                    <span
                      className={`${
                        false && "error"
                      }`}
                    >{`Question ${qno}`}</span>
                    {points>0 && <span
                      className={`points_count ${
                        false && "error"
                      }`}
                    >{`${points}pts`}</span>}
                  </StyledStepLabel>
                </StyledStep>
              );
            })}
          </StyledStepper>
          <React.Fragment>
            {/* content */}
            <Box className="steps">
              <Box className="stepperForm">
                <StepperContainer
                  activeStep={activeStep}
                  answerData={answerData}
                  data={steps.filter((va: any) => va.qno === activeStep + 1)[0]}
                  index={steps.findIndex((va: any) => va.qno === activeStep + 1)}
                  handleChange={(e:any,index:any,qid:any)=>handleChange(e,index,qid)}
                />
                <Box 
                // sx={{ minHeight: 'calc(100vh - 500px)' }}
                 className="foot">
                  <Button
                    className="btn_red"
                    disabled={loading|| !answerData[activeStep]}
                    onClick={
                    ()=>handleNext(answerData)
                    }
                  >
                    {submitBtnName}
                    {loading ? (
                      <CircularProgress
                        sx={{
                          color: "white",
                          height: "20px !important",
                          width: "20px !important",
                          marginLeft: "8px",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                  {/* <p>Caution: “Tobacco Causes Cancer”</p> */}
                  </Box>
                </Box>

              <HoldOn
                show={showConfirmation}
                setShowConfirmation={setShowConfirmation}
                handleBacked={() => navigate('/tasks')}
              />
            </Box>
          </React.Fragment>
        </>
    </Box>
  );
}
