import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { MediaPlayer, MediaPlayerInstance, MediaProvider, Poster, useMediaStore } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';

interface VideoComponentProps {
    onVideoEnd: () => void;
    videoUrl?: string;
    posterUrl?: string;
}

const VidStackVideo: React.FC<VideoComponentProps> = ({ onVideoEnd, videoUrl, posterUrl, }) => {
    const playerRef = useRef<MediaPlayerInstance>(null);
    const { canFullscreen } = useMediaStore(playerRef);

    const handleEnableFullScreen = async () => {
        if (playerRef.current) {
            const player = playerRef.current;
            if (canFullscreen) {
                try {
                    await player.enterFullscreen('prefer-media');
                } catch (e) {
                    console.error('Error entering fullscreen:', e);
                }
            } else {
                console.error('Fullscreen is not supported in this environment');
            }
        }
    };
    const lastKnownTime = useRef(0); // Store the last valid playback position

  useEffect(() => {
    const player = playerRef.current;

    if (!player) {
      console.error('Player instance not found.');
      return;
    }

    const preventSeek = () => {
      if (!player) return;

      const currentTime = player.currentTime;

      // If a seek is detected, revert to the last known time
      if (Math.abs(currentTime - lastKnownTime.current) > 0.1) {
        console.warn('Seeking is disabled.');
        player.currentTime = lastKnownTime.current;
      }
    };

    const updateTime = () => {
      if (!player) return;
        console.log("player.currenttime",player.currentTime)
      // Update the last known time only during normal playback
      lastKnownTime.current = player.currentTime;
    };

    // Add event listeners
    player.addEventListener('seeking', preventSeek);
    player.addEventListener('timeupdate', updateTime);

    return () => {
      // Cleanup event listeners
      player.removeEventListener('seeking', preventSeek);
      player.removeEventListener('timeupdate', updateTime);
    };
  }, []);

    return (
        <Box sx={{ width: "100%" }}>
            <MediaPlayer
                className="videoPlayerrrrrr"
                ref={playerRef as any}
                playsInline
                loop={false}
                src={videoUrl}
                crossOrigin
                onEnded={onVideoEnd}
                onPlay={handleEnableFullScreen}
                fullscreenOrientation="landscape-primary"
                controls={false} // Disable native controls
            >
                <MediaProvider>
                    <Poster
                        className="vds-poster"
                        src={posterUrl}
                        crossOrigin="anonymous"
                        alt="poster"
                    />
                </MediaProvider>
                <DefaultVideoLayout
                    colorScheme="default"
                    icons={defaultLayoutIcons}
                    disableTimeSlider={true}
                    slots={{
                        smallLayout: {
                            seekForwardButton: false,
                            seekBackwardButton: false,
                            settingsMenuItemsStart: false,
                        },
                        largeLayout: {
                            seekForwardButton: false,
                            seekBackwardButton: false,
                            settingsMenuItemsStart: false,
                        },
                    }}
                />
            </MediaPlayer>
        </Box>
    );
};

export default VidStackVideo;