// AlertComponent.js
import React, { useContext } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { AlertContext } from '../context/AlertContext';

const AlertComponent = () => {
  const { alert, closeAlert } = useContext(AlertContext);

  return (
    <Snackbar className='wrongAnswer' open={alert.open} anchorOrigin={{vertical:'top',horizontal:'center'}} autoHideDuration={1000} onClose={closeAlert}>
      <Alert onClose={closeAlert} severity={alert.severity}>
        {alert.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertComponent;
