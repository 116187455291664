import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
// import KnowYourProducts from "./pages/KnowYourProducts";
import "./styles/main.scss";
import Points from "./pages/Points";
import PointsNew from "./pages/PointsNew";
import AlertComponent from "./components/AlertComponent";
import Videos from "./pages/Videos";
import Tasks from "./pages/Tasks";
import Notifications from "./pages/Notifications";
import Activitylog from "./pages/Activitylog";
import Profile from "./pages/Profile";
import TasksDetails from "./pages/TasksDetails";
import Auth from "./pages/Auth";
import AuthWrapper from "./pages/AuthWrapper";
import './i18n';
import { useTranslation } from "react-i18next";
import { KEY_LANG } from "./utils/contants";
import HomertaskCard from "./pages/HomeTaskCard";
import HomeActiveTasks from "./pages/HomeActiveTasks";
import HomePage from "./pages/HomePage";
import Hidden from "./pages/Hidden";
import ComingSoon from "./pages/comingSoon";
import Brand from "./pages/Brand";
import BrandDetails from "./pages/BrandDetails";
import RewardPoints from "./pages/RewardPoints";
import InvisibleIframe from "./pages/InvisibleIframe";
import BuildYourStickTask from "./pages/BuildYourSticktask";
import ViewIRResult from "./pages/ViewIRResult";

function App() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { i18n } = useTranslation();

  useEffect(() => {
    const params: any = {};
    queryParams.forEach((value, key) => {
      params[key] = value;
    });
    console.log("parama11", params, location);
  }, [location.search]);
  useEffect(() => {
    localStorage.setItem(KEY_LANG, 'hi');
    function sendHeight() {
      var height = document.documentElement.scrollHeight;

      window.parent.postMessage({ action: 'dynamicHeight', label: height }, '*');
    }

    window.onload = sendHeight;
    window.onresize = sendHeight;
  }, [])
  return (
    <div className="App">
      <AlertComponent />
      <Routes>
        {/* <Route path={`/task1`} element={<KnowYourProducts />} /> */}
        <Route path={`/`} element={<Auth />} />
        <Route path={`/points/:id`} element={<Points />} />
        <Route path={`/points-v1/:id`} element={<PointsNew />} />
        <Route path={`/videos`} element={<Videos />} />
        <Route path={`/tasks`} element={<AuthWrapper><Tasks /></AuthWrapper>} />
        <Route path={`/tasks/:id`} element={<AuthWrapper adjustHeight={false}><TasksDetails /></AuthWrapper>} />
        <Route path={`/notifications`} element={<Notifications />} />
        <Route path={`/activitylog`} element={<Activitylog />} />
        <Route path={`/profile`} element={<Profile />} />
        <Route path={`/home-task`} element={<HomertaskCard />} />
        <Route path={`/home-active-tasks`} element={<HomeActiveTasks />} />
        <Route path={`/home`} element={<HomePage />} />
        <Route path={`/hidden`} element={<Hidden />} />
        <Route path={`/invisible`} element={<InvisibleIframe />} />
        <Route path={`/coming-soon`} element={<ComingSoon />} />
        <Route path={`/brand`} element={<Brand />} />
        <Route path={`/brand-details`} element={<BrandDetails />} />
        <Route path={`/points-history`} element={<RewardPoints/>} />
        <Route path={`/stick-task`} element={<BuildYourStickTask />} />
        <Route path={`/view-ir-results`} element={<ViewIRResult />} />
      </Routes>
    </div>
  );
}

export default App;