import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

const DragDrop: React.FC<any> = ({questData}:any) => {
    const [targetColorOne, setTargetColorOne] = useState<string | null>(null);
    const [targetColorTwo, setTargetColorTwo] = useState<string | null>(null);
    const [isSecondDropZoneEnabled, setIsSecondDropZoneEnabled] = useState<boolean>(false);
    const [draggedFlavor, setDraggedFlavor] = useState<string | null>(null);

    const flavors = [
        { name: "Blackcurrant", gradient: "radial-gradient(42.5% 42.5% at 50% 37.5%, #BEADFF 0%, #482C6B 100%)" },
        { name: "Mint", gradient: "radial-gradient(42.5% 42.5% at 50% 37.5%, #D6FDBD 0%, #1D9D01 100%)" },
        { name: "Blueberry", gradient: "radial-gradient(42.5% 42.5% at 50% 37.5%, #B7DCF7 0%, #0E98C1 100%)" },
        { name: "Strawberry", gradient: "radial-gradient(42.5% 42.5% at 50% 37.5%, #FFEBF4 0%, #C10E62 100%)" },
        { name: "Menthol", gradient: "radial-gradient(42.5% 42.5% at 50% 37.5%, #B7DCF7 0%, #003CD0 100%)" },
    ];

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>, gradient: string) => {
        event.dataTransfer.setData("gradient", gradient);
        setDraggedFlavor(gradient);
    };

    const handleTouchStart = (gradient: string) => {
        setDraggedFlavor(gradient);
    };

    const handleDropOne = () => {
        if (draggedFlavor) {
            const flavor = flavors.find((flavor) => flavor.gradient === draggedFlavor);
            if (flavor) {
                console.log({ dropZone: "DropZoneOne", name: flavor.name });
                setTargetColorOne(draggedFlavor);
                setIsSecondDropZoneEnabled(true);
                setDraggedFlavor(null);
            }
        }
    };

    const handleDropTwo = () => {
        if (draggedFlavor && isSecondDropZoneEnabled) {
            const flavor = flavors.find((flavor) => flavor.gradient === draggedFlavor);
            if (flavor) {
                console.log({ dropZone: "DropZoneTwo", name: flavor.name });
                setTargetColorTwo(draggedFlavor);
                setDraggedFlavor(null);
            }
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    return (
        
        <Box sx={{ background: "#FFFFFF", padding: "16px", borderRadius: "16px", textAlign: "start" }}>
            <Typography sx={{ fontWeight: "600", fontSize: "20px", lineHeight: "28px", color: "#282829", marginBottom: "8px" }}>
                Select the flavors for Vista Double Fusion
            </Typography>
            <Typography sx={{ fontWeight: "400", fontSize: "12px", lineHeight: "14.52px", color: "#282829", marginBottom: "20px" }}>
                Drag and drop the correct flavor into the drop zones in order.
            </Typography>

            <Box sx={{ display: "flex", width: "100%" }}>
                <div style={{ justifyContent: "center", gap: "20px", maxWidth: "284px", width: "100%" }}>
                    {flavors.map((flavor) => (
                        <Box
                            key={flavor.name}
                            draggable
                            onDragStart={(e) => handleDragStart(e, flavor.gradient)}
                            onTouchStart={() => handleTouchStart(flavor.gradient)}
                            sx={{
                                display: "flex",
                                padding: "10px 12px",
                                maxWidth: "180px",
                                border: "1px solid #F1F2F3",
                                borderRadius: "10px",
                                marginBottom: "16px",
                                cursor: "grab",
                                userSelect: "none",
                                touchAction: "none",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    lineHeight: "24px",
                                    color: "#282829",
                                    userSelect: "none",
                                }}
                            >
                                {flavor.name}
                            </span>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "auto",
                                    borderRadius: "50%",
                                    userSelect: "none",
                                }}
                            >
                                <div
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                        background: flavor.gradient,
                                    }}
                                ></div>
                            </div>
                        </Box>

                    ))}
                </div>

                <div style={{ display: "flex", justifyContent: "center", gap: "40px", marginTop: "20px" }}>
                    <Box sx={{ position: "relative" }}>
                        <img
                            src={"/images/CigStick.png"}
                            alt={"imgStick"}
                            style={{
                                height: "264px",
                                borderRadius: "8px",
                                maxWidth: "335px",
                            }}
                        />
                        <Box
                            sx={{ position: "absolute", top: "137px", transform: "translate(-50%, -50%)", left: "50%", width: "40px", height: "50px" }}
                            onDrop={handleDropOne}
                            onDragOver={handleDragOver}
                            onTouchEnd={handleDropOne}
                        >
                            <div
                                style={{
                                    width: "20px",
                                    height: "20px",
                                    border: targetColorOne ? "none" : "1px solid rgba(200, 23, 47, 1)",
                                    borderRadius: "50%",
                                    background: targetColorOne || "rgba(255, 255, 255, 1)",
                                    boxShadow: targetColorOne ? "none" : "2px 2px 8px 8px rgba(200, 23, 47, 0.20)",
                                    position: "absolute", top: "50%", transform: "translate(-50%, -50%)", left: "50%",
                                }}
                            ></div>
                        </Box>
                        <Box
                            sx={{ position: "absolute", top: "200px", transform: "translate(-50%, -50%)", left: "50%", width: "40px", height: "50px" }}
                            onDrop={handleDropTwo}
                            onDragOver={(e) => isSecondDropZoneEnabled && handleDragOver(e)}
                            onTouchEnd={handleDropTwo}
                        >
                            <div
                                style={{
                                    width: "20px",
                                    height: "20px",
                                    border: isSecondDropZoneEnabled
                                        ? targetColorTwo
                                            ? "none"
                                            : "1px solid rgba(200, 23, 47, 1)"
                                        : "1px solid #FFF",
                                    borderRadius: "50%",
                                    background: targetColorTwo || "rgba(255, 255, 255, 1)",
                                    opacity: isSecondDropZoneEnabled ? 1 : 0.5,
                                    boxShadow: isSecondDropZoneEnabled
                                        ? targetColorTwo
                                            ? "none"
                                            : "2px 2px 8px 8px rgba(200, 23, 47, 0.20)"
                                        : "none",
                                    pointerEvents: isSecondDropZoneEnabled ? "auto" : "none",
                                    position: "absolute", top: "50%", transform: "translate(-50%, -50%)", left: "50%",
                                }}
                            ></div>
                        </Box>
                    </Box>
                </div>
            </Box>
        </Box>
    );
};

export default DragDrop;
