import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step, { StepProps } from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  CircularProgress,
  StepConnector,
  stepConnectorClasses,
  StepIcon,
} from "@mui/material";
import styled from "@emotion/styled";

import { useForm } from "react-hook-form";
import "./stepper.scss";

import { Cancel } from "@mui/icons-material";
import { css } from "@emotion/react";
// import back from '../../assests/images/back.svg'
import BannerImg from "../../assests/images/bannerImg.svg";
import VideoComponent from "../videos";
import NoteCoin from "../videos/NoteCoin";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Icons from "../Icons";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import VideoCompPlayFullScreen from "../videos/VideoCompPlayFullScreen";
import VidStackVideo from "../videos/vidStackVideo";
const QontoConnector = styled(StepConnector)(({ theme }: any) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 10px)",
    right: "calc(50% + 10px)",
    zIndex: "-1",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#C8172F",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#C8172F",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#FFEBEF",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));
const StyledStepper = styled(Stepper)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));
interface StyledStepProps extends StepProps {
  error?: boolean; // Add the error prop as optional
}

const StyledStep = styled(Step)<StyledStepProps>(({ theme, error }) =>
  css({
    padding: "0 !important",
    ".MuiSvgIcon-root": {
      borderRadius: "30px",
      border: "1px solid #C5C6C7",
      background: "#F5F6F7",
      color: "transparent",
      "&.Mui-active": {
        borderRadius: "30px",
        border: "1px solid #C8172F",
        background: "#FFEBEF",
        color: "transparent",
        ".MuiStepIcon-text": {
          fill: "#C8172F",
        },
      },
      "&.Mui-completed": {
        color: "#46C65A",
        border: "0",
      },
      ".MuiStepIcon-text": {
        fill: "#7D7E7F",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "normal",
        letterSpacing: "0.12px",
      },
    },
    ".MuiStepLabel-labelContainer": {
      ".MuiStepLabel-label": {
        color: "#7D7E7F",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        letterSpacing: "0.12px",
        marginTop: "4px",
        ".points_count": {
          display: "block",
          color: "#7D7E7F",
          textAlign: "center",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "18px",
          letterSpacing: "0.12px",
          ".error": {
            color: "#C8172F",
          },
        },
        ".error": {
          color: "#C8172F",
        },
      },
    },
    // Apply conditional styles based on the error prop
    ...(error && {
      ".MuiSvgIcon-root": {
        color: "#C8172F",
      },
      ".MuiStepLabel-labelContainer .MuiStepLabel-label": {
        color: "#C8172F", // Change label color if there's an error
      },
    }),
  })
);

const StyledStepLabel = styled(StepLabel)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    // Custom styles for step labels in mobile
    fontSize: "1rem",
  },
}));

export default function WatchVideos({ questData }: any) {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const TOTALATTEMPTS = questData?.data?.location === "Mumbai" ? 2 : 4;

  const [skipped, setSkipped] = React.useState(new Set<number>());
  const { register, handleSubmit, watch, formState } = useForm<any>();
  const [attempts, setAttempts] = React.useState<any>(0);
  const [showConfirmation, setShowConfirmation] =
    React.useState<boolean>(false);
  const [startquiz, setStartquiz] = React.useState<boolean>(false);
  const [correctAnswer, setCorrectAnswer] = React.useState<null | any>(null);
  const [isStepFailed, setIsStepFailed] = React.useState<Array<number | null>>(
    []
  );
  const [isDirty, setIsDirty] = React.useState(false);
  const [isVideoWatched, setIsVideoWatched] = React.useState<boolean>(false);
  const [submitBtnName, setSubmitBtnName] = React.useState<string>(t('taskdetaitls.watch_videos.quest_submit_btn'));
  const [answerData, setAnswerData] = React.useState<
    Array<{
      task_id: string;
      question_id: string;
      answer: string;
      no_of_attempts: string;
      is_correct: boolean;
    }>
  >([]);
  const authContext = React.useContext(AuthContext);

  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const encryptedTaskId = window.btoa(questData.data.task_id.toString());

  const steps =
    questData?.data?.subtasks?.map((subtask: any) => ({
      qno: subtask?.question_no,
      qid: subtask?.question_id,
      points: subtask?.points,
      quest: subtask?.question,
      opts: subtask?.options.map((option: any) => option.option_value),
      ans: subtask?.options.find((option: any) => option.correct_option === "1")
        ?.option_value,
      hint: subtask?.hint?.content_type,
    })) || [];



  const storeAnswerNew = async (answerData: any) => {
    setLoading(true);
    if (authContext) {
      const { token } = authContext;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/answer/video-store-answer`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              'Accept-Language': i18n.language,
            },
            body: JSON.stringify([answerData]),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to store answers");
        }
        setLoading(false);

        const result = await response.json();
        // navigate(`/points-v1/${encryptedTaskId}`);

        window.parent.postMessage({ action: 'redirectTo', label: 'points-history' }, '*')
        console.log("Answers stored successfully:", result);
      } catch (error) {
        setLoading(false);
        console.error("Error submitting answer", error);
      }
    }
  };

  React.useEffect(() => {
    const subscription = watch((value) => {
      console.log("Form fields changed:", value);
      setIsDirty(true);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
  };

  return (
    <Box sx={{
      width: "100%",
      // height: !startquiz ? '450px' : '500px', overflowY: "auto"
    }}>
      <>
        {" "}
        {/* <VideoComponent onVideoEnd={handleVideoEnd} videoUrl={i18n.language==="hi"?questData?.data?.introdution_video?.replace("passbook_animation", "passbook_animation_hindi"):questData?.data?.introdution_video} posterUrl={questData?.data?.introdution_video}/> */}
        {/* <VideoCompPlayFullScreen onVideoEnd={handleVideoEnd} videoUrl={i18n.language === "hi" ? questData?.data?.introdution_video?.replace("passbook_animation", "passbook_animation") : questData?.data?.introdution_video} posterUrl={questData?.data?.introdution_video} /> */}
        <VidStackVideo onVideoEnd={handleVideoEnd} videoUrl={i18n.language === "hi" ? questData?.data?.introdution_video?.replace("passbook_animation", "passbook_animation") : questData?.data?.introdution_video} posterUrl={questData?.data?.intro_poster} />
        <Box className="Video">
          <Box
            // sx={{ minHeight: 'calc(100vh - 500px)' }}
            className="foot">
            <Button
              disabled={!isVideoWatched}
              onClick={() => storeAnswerNew(
                {
                  task_id: questData.data.task_id,
                  question_id: steps[activeStep]?.qid,
                  answer: "wtched",
                  no_of_attempts: 1,
                  is_correct: 1,
                }
              )}
              className="btn_red"
            >
              {t('taskdetaitls.watch_videos.quest_submit_btn')}
            </Button>
            {/* <p>Caution: “Tobacco Causes Cancer”</p> */}
          </Box>
        </Box>
        <NoteCoin
          note={t('taskdetaitls.watch_videos.note1')}
          note_label={t('taskdetaitls.watch_videos.note_label')}
          home="home"
          iconHeight={130}
        />
      </>
    </Box>
  );
}
