import React, {
  forwardRef,
  useState,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useDrag, useDrop } from "react-dnd";
import { Box, FormControl, FormLabel, Typography } from "@mui/material";
import { AlertContext } from "../../context/AlertContext";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import "./stepper.scss";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

import { DndProvider, TouchTransition, MouseTransition, usePreview } from 'react-dnd-multi-backend'
import CustomDragLayer from "./Item";
import { useDragState } from "./DragStateContext";


export const HTML5toTouch = {
  backends: [
    {
      id: "touch",
      backend: TouchBackend,
      options: {
        enableMouseEvents: true,
        enableHoverOutsideTarget: true, // Allow hover detection outside the touch point
        ignoreContextMenu: true, // Prevent default context menu on long press
      },
      preview: true,
      transition: TouchTransition
    }
  ]
};
// Item Types
const ItemTypes = {
  FLAVOR: "flavor",
};

interface Flavor {
  name: string;
  gradient: string;
}

interface StepperContainerProps {
  data: {
    quest: string;
    opts: string[];
    qno: number;
  };
  register: any;
  isStepFailed: boolean;
  watch: any;
  activeStep: number;
  TOTALATTEMPTS: number;
  setAttempts: React.Dispatch<React.SetStateAction<number>>;
  setCorrectAnswer: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: any;
  hint: string;
}

const getMainColorFromGradient = (gradient: string): string => {
  const match = gradient.match(
    /(?:rgb(?:a)?\([^)]*\)|#[0-9a-fA-F]{3,6}|[a-zA-Z]+)(?=\s*\d*\.?\d*%?\s*,|\))/
  );
  return match ? match[0] : "#000000"; // Default to black if no color is found
};

export interface StepperContainerHandle {
  validateAns: (
    selectedValue: string | null,
    correctAnswer: string,
    attempts: number
  ) => string;
}



const StepperContainer = forwardRef<
  StepperContainerHandle,
  StepperContainerProps
>((props, ref) => {
  const {
    activeStep,
    isStepFailed,
    setValue,
    setAttempts,
    TOTALATTEMPTS,
    hint,
    data: { quest, opts, qno },
    register, // Receive the register function from the parent
  } = props;

  const { showAlert } = useContext(AlertContext);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [status, setStatus] = useState<string | null>(null);
  const [showHint, setShowHint] = useState(false);
  const [targetColorOne, setTargetColorOne] = useState<string | null>(null);
  const [targetColorTwo, setTargetColorTwo] = useState<string | null>(null);
  const [targetValueOne, setTargetValueOne] = useState<string | null>(null);
  const [targetValueTwo, setTargetValueTwo] = useState<string | null>(null);
  const dragLayerRef = useRef<any>(null);
  const validateAns = (
    selectedValue: string | null,
    correctAnswer: string,
    attempts: number
  ) => {
    if (selectedValue !== null) {
      setSelectedValue(selectedValue);
      if (selectedValue === correctAnswer) {
        setStatus("correct");
        showAlert(t("taskdetaitls.buildstick.alertSuccessMessage"), "success");
        setShowHint(false);
        return "correct";
      } else {
        setStatus("wrong");
        showAlert(t("taskdetaitls.buildstick.alertErrorMessage"), "error");
        setShowHint(false);
        setValue("answer", "");
        if(activeStep===0){
          setTargetColorOne(null)
        }else{
          setTargetColorTwo(null)
        }
        if (attempts < TOTALATTEMPTS) {
          setAttempts((prev) => prev + 1);
        } else {
          setShowHint(false);
        }
        return "wrong";
      }
    }
    return "wrong";
  };

  useImperativeHandle(ref, () => ({ validateAns }));

  useEffect(() => {
    setStatus(null);
    setShowHint(false);
  }, [activeStep]);
  // Draggable Flavor Component
const DraggableFlavor = ({ flavor }: { flavor: Flavor }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.FLAVOR,
    item: flavor,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        display: "flex",
        padding: "10px 12px",
        maxWidth: "180px",
        border: "1px solid #F1F2F3",
        borderRadius: "10px",
        marginBottom: "16px",
        cursor: "grab",
        backgroundColor: "#fff",
        userSelect: "none",
        touchAction: "none",
        alignItems: "center",
        opacity: isDragging ? 0.5 : 1, // Show the dragged element as semi-transparent
        transform: isDragging ? "scale(1.1)" : "none", // Increase size during dragging for better visibility
        boxShadow: isDragging
          ? "0 4px 10px rgba(0, 0, 0, 0.2)"
          : "0 2px 6px rgba(0, 0, 0, 0.1)", // Add shadow while dragging
        transition: "all 0.2s ease", // Smooth transition for effects
      }}
    >
      <span
        style={{
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
          color: "#282829",
        }}
      >
        {flavor.name}
      </span>
      <div
        style={{
          marginLeft: "auto",
          borderRadius: "50%",
          width: "20px",
          height: "20px",
          background: flavor.gradient,
        }}
      ></div>
    </div>
  );
};

// Drop Zone Component
const DropZone = ({
  targetColor,
  setTargetColor,
  setTargetValue,
  setValue,
  enabled,
  borderColor,
  setisOver,
}: {
  targetColor: string | null;
  setTargetColor: (color: string) => void;
  setTargetValue: (name: string) => void;
  setisOver: (name: boolean) => void;
  setValue: (name: string, value: string) => void;
  enabled: boolean;
  borderColor: string;
}) => {
  

  const [{ isOver, item }, drop] = useDrop(() => ({
    accept: ItemTypes.FLAVOR,
    canDrop: () => enabled, 
    drop: (item: Flavor) => {
      if (enabled) {
        setTargetColor(item.gradient); // Update color to the dropped item’s gradient
        setTargetValue(item.name); // Update color to the dropped item’s gradient
        setValue("answer", item.name); // Update color to the dropped item’s gradient
      }
    }, 
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      item: monitor.getItem()
    }),
    
  }));

  const hoverBorderColor =
    isOver && item
      ? getMainColorFromGradient(item.gradient) // Use the extracted main color
      : targetColor !== null
      ? targetColor
      : borderColor;
  useEffect(()=>{
    if(enabled)
    setisOver(isOver)
  },[isOver])
  return (
    <div
      ref={drop}
      style={{
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        background: enabled||(!enabled && targetColor)
          ? targetColor || "#fff" // Use the target color or fallback to white when enabled
          : "#d3d3d3", // Disabled color (e.g., light gray)
        border: `2px solid ${enabled ? "none" : "#a9a9a9"}`, // Use a lighter border for disabled
        boxShadow: enabled
          ? targetColor
            ? `0px 0px 4px 2px ${hoverBorderColor}`
            : "none"
          : "none", // No shadow when disabled
        transform: isOver ? "scale(1.1)" : "none", // Enlarge when hovered
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "border-color 0.2s ease-in-out, background 0.2s ease-in-out", // Smooth transition effect
      }}
    >
       {/* Invisible larger hitbox */}
   {enabled && <div
      style={{
        position: "absolute",
        top: "-10px",
        left: "-30px",
        width: "80px",
        height: "44px",
        borderRadius: "50%",
        background: "transparent",
        zIndex: -1,
      }}
    />}
    </div>
  );
};
  return quest ? (
    <>
      <input style={{display:"none"}} {...register("answer")} />
      <DndProvider options={HTML5toTouch}>
      <CustomDragLayer ref={dragLayerRef}/>
        <Box sx={{padding:'16px'}}>
          <FormLabel component="legend" sx={{fontSize:'20px',fontWeight:600,lineHeight:"28px",color:'#282829',marginBottom:"8px"}}>{quest}</FormLabel>
          <Typography sx={{fontSize:'12px',fontWeight:400,lineHeight:"14px"}}>{t("taskdetaitls.buildstick.note")}</Typography>
          {showHint && (
            <Box className="hint">
              <img src="/images/hint.svg" alt="hint" />
              <Typography variant="body2">Hint: {hint}</Typography>
            </Box>
          )}
          <Box sx={{ display: "flex", marginTop:'20px', width: "100%" }}>
            <div
              style={{
                justifyContent: "center",
                gap: "20px",
                maxWidth: "284px",
                width: "100%",
                alignItems:'flex-end'
              }}
            >
              {opts.map((flavorName) => {
                const flavor = flavors.find((f) => f.name === flavorName);
                return (
                  flavor && (
                    <DraggableFlavor key={flavor.name} flavor={flavor} />
                  )
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "40px",
                marginTop: "0px",
              }}
            >
              <Box sx={{ position: "relative" }}>
                <img
                  src={`/images/cigrate-capsule-${qno}.svg`}
                  alt="cigarette"
                  crossOrigin="anonymous"
                  width="90"
                  height="190"
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "108px",
                    left: "52%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <DropZone
                    targetColor={targetColorOne}
                    setTargetColor={setTargetColorOne}
                    setTargetValue={setTargetValueOne}
                    setisOver={(va)=>{
                      if(va){
                        console.log("dragLayerRef",dragLayerRef)
                        if (dragLayerRef.current) {
                          dragLayerRef.current.changeBackgroundColor("#ffcccc");
                        }
                      }
                      else{
                        console.log("dragLayerRef",dragLayerRef)
                        if (dragLayerRef.current) {
                          dragLayerRef.current.changeBackgroundColor("#fff");
                        }
                      }
                    }}
                    setValue={setValue}
                    enabled={((activeStep+1) === 1)}
                    borderColor={(activeStep+1) ===  1? "rgba(200, 23, 47, 1)" : "#FFF"}
                  />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "57px",
                    left: "52%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <DropZone
                    targetColor={targetColorTwo}
                    setTargetColor={setTargetColorTwo}
                    setTargetValue={setTargetValueTwo}
                    setisOver={(va)=>{
                      if(va){
                        console.log("dragLayerRef",dragLayerRef)
                        if (dragLayerRef.current) {
                          dragLayerRef.current.changeBackgroundColor("#ffcccc");
                        }
                      }
                      else{
                        console.log("dragLayerRef",dragLayerRef)
                        if (dragLayerRef.current) {
                          dragLayerRef.current.changeBackgroundColor("#fff");
                        }
                      }
                    }}
                    enabled={((activeStep+1) === 2)}
                    setValue={setValue}
                    borderColor={(activeStep+1) ===  2? "rgba(200, 23, 47, 1)" : "#FFF"}
                  />
                </Box>
              </Box>
            </div>
          </Box>

          {/* Use Controller for input registration */}
        </Box>
      </DndProvider>
    </>
  ) : null;
});

// Sample flavors
const flavors: Flavor[] = [
  {
    name: "Blackcurrant",
    gradient:
      "radial-gradient(42.5% 42.5% at 50% 37.5%, #BEADFF 0%, #482C6B 100%)",
  },
  {
    name: "Mint",
    gradient:
      "radial-gradient(42.5% 42.5% at 50% 37.5%, #D6FDBD 0%, #1D9D01 100%)",
  },
  {
    name: "Blueberry",
    gradient:"radial-gradient(42.5% 42.5% at 50% 37.5%, #BEADFF 0%, #482C6B 100%)"

  },
  {
    name: "Strawberry",
    gradient:
      "radial-gradient(42.5% 42.5% at 50% 37.5%, #FFEBF4 0%, #C10E62 100%)",
  },
  {
    name: "Menthol",
    gradient:
      "radial-gradient(42.5% 42.5% at 50% 37.5%, #B7DCF7 0%, #003CD0 100%)",
  },
  {
    name: "Citrus",
    gradient:
      "radial-gradient(42.5% 42.5% at 50% 37.5%, #D6FDBD 0%, #1D9D01 100%)",
  },
  {
    name: "ब्ल्यूबेरी",
    gradient:"radial-gradient(42.5% 42.5% at 50% 37.5%, #BEADFF 0%, #482C6B 100%)"

  },
  {
    name: "स्ट्रॉबेरी",
    gradient:
      "radial-gradient(42.5% 42.5% at 50% 37.5%, #FFEBF4 0%, #C10E62 100%)",
  },
  {
    name: "मेन्थोल",
    gradient:
      "radial-gradient(42.5% 42.5% at 50% 37.5%, #B7DCF7 0%, #003CD0 100%)",
  },
  {
    name: "सिट्रस",
    gradient:
      "radial-gradient(42.5% 42.5% at 50% 37.5%, #D6FDBD 0%, #1D9D01 100%)",
  },
];

export default StepperContainer;
