import React, { useContext, useEffect, useState } from 'react';
import './TransactionsLists.scss';
import { Box, Button, Drawer, Typography } from '@mui/material';
import Icons from '../Icons';

import { AuthContext } from "../../context/AuthContext";
import i18n from "../../i18n";
import { useTranslation } from 'react-i18next';

type Transaction = {
    qutionsanswer: string;
    id: string,
    title: string;
    date: string;
    status?: string;
    points: number;
    type: string
};


type PointsCardsProps = {
    transactions: Transaction[];

};

const PointsCards: React.FC<PointsCardsProps> = ({ transactions }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedTransaction, setSelectedTransaction] = React.useState<Transaction | null>(null);
    const [selectedtest2, setSelectedtest] = React.useState<Transaction | null>(null);
    const authContext = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const { token }: any = authContext;
    const { t } = useTranslation();




    const fetchTransactionDetails = async (transaction: Transaction) => {
        setLoading(true);
        try {

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/task/get_all_pacebook_task_id_details`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept-Language': i18n.language,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    task_id: transaction.id,
                    token: token,
                }),
            });

            const data = await response.json();
            const details = {
                title: data?.data?.task_name || "No Title",
                date: data?.data?.updated_at || "No Date",
                points: data?.data?.pointsEarned || 0,
                status: data?.data?.status || "",
                id: "",
                qutionsanswer: data?.data?.questionsAnswers,
                type: ""
            };

            setSelectedTransaction(details);
        } catch (error) {
            console.error('Error fetching task details:', error);
        } finally {
            setLoading(false);
        }
    };




    const toggleDrawer = (open: boolean, transaction?: Transaction) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            if (transaction?.type === '') {
                setSelectedtest(null);
                fetchTransactionDetails(transaction);
            } else if (transaction?.type === 'gifts') {
                setSelectedTransaction(null);
                setSelectedtest(transaction || null);
            }

            setIsOpen(open);
        };


    console.log(selectedtest2, "selectedtest2selectedtest2selectedtest2")
    console.log(selectedTransaction, "selectedTransactionselectedTransactionselectedTransaction")
    return (
        <>
            <Box sx={{ padding: "0 20px", overflowY: "scroll", height: "calc(100vh - 235px)" }}>
                {transactions.map((transaction, index) => (
                    <Box key={index} sx={{ display: 'flex', borderBottom: "1px solid #d7d7d7", padding: "12px 0px" }}>
                        <Box
                            sx={{
                                background: transaction.title === "Task Completed" ? '#F5F6F7' : '#FFF0EA',
                                padding: "9px", borderRadius: "50%", width: "42px", height: "42px", marginRight: "10px"
                            }}
                        >
                            <Icons width={24} height={24} name={transaction.title === "Task Completed" ? "verify" : "ticketStar"} />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: "center", width: "100%" }}>
                            <Box sx={{ textAlign: "start" }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "20px", color: "#282829" }}>
                                    {transaction.title}
                                </Typography>
                                <Typography sx={{ fontSize: "12px", fontWeight: "500", lineHeight: "16px", color: "#696A6B" }}>
                                    {transaction.date}
                                </Typography>
                                <Box
                                    onClick={toggleDrawer(true, transaction)}
                                    sx={{
                                        fontSize: transaction.status ? "12px" : "14px",
                                        cursor: "pointer",
                                        color: transaction.status ? "#DD7A00" : "#C8172F",
                                        lineHeight: transaction.status ? "16px" : "20px",
                                        fontWeight: transaction.status ? "600" : "500",
                                        marginTop: "6px",
                                        padding: transaction.status ? "6px" : "0",
                                        background: transaction.status ? "#FFF0EA" : "transparent",
                                        borderRadius: transaction.status ? "8px" : "0"
                                    }}
                                >
                                    {"View Details"}
                                </Box>

                            </Box>
                            <Box sx={{ marginLeft: "auto" }}>
                                <Icons width={16} height={16} name={'brokenCoin'} />
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: "700",
                                        lineHeight: "16px",
                                        color: transaction.points > 0 ? "#2E844A" : "#B90022"
                                    }}
                                >
                                    {transaction.points > 0 ? `${transaction.points} ${t("pts")}` : `${transaction.points} ${t("pts")}`}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>

            <Drawer
                anchor="bottom"
                open={isOpen}
                onClose={toggleDrawer(false)}
                sx={{ ".MuiPaper-elevation": { borderRadius: "20px 20px 0 0", overflowY: "unset" } }}
            >
                <Box onClick={toggleDrawer(false)} sx={{
                    position: "absolute", top: "-20px", right: "50%",
                    transform: "translate(50% ,-50%)", cursor: "pointer"
                }}>
                    <Icons width={24} height={24} name={'closeIcon'} />
                </Box>

                <Box sx={{ padding: "20px 17px 0px" }}>
                    {selectedTransaction && selectedTransaction.type == "" && !selectedtest2 && (
                        <Box sx={{ textAlign: "center" }}>
                            <Icons width={68} height={68} name={'fileDicon'} />
                            <Typography sx={{ fontSize: "18px", lineHeight: "28px", fontWeight: "600", color: "#282829", marginBottom: "2px" }}>{selectedTransaction.title}</Typography>
                            <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500", color: "#7D7E7F", marginBottom: "16px" }}>{selectedTransaction.date}</Typography>
                            <Box sx={{ display: "flex", alignItems: "center", width: "100%", gap: "16px", marginBottom: "16px" }}>
                                <Box sx={{ display: "flex", width: "50%", alignItems: "center", background: "#FFF0E1", padding: "10px", borderRadius: "12px", gap: "3px" }}>
                                    <Icons width={30} height={30} name={'penDicon'} />
                                    <Box>
                                        <Typography sx={{ fontSize: "14px", lineHeight: "18.2px", fontWeight: "600", color: "#282829" }}>{selectedTransaction.points} {t("pts")}</Typography>
                                        <Typography sx={{ fontSize: "12px", lineHeight: "15.6px", fontWeight: "500", color: "#494A4B" }}>{t("transactions_popup.points_earned")}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", width: "50%", alignItems: "center", background: "#F9FBFC", padding: "10px", borderRadius: "12px", gap: "3px" }}>
                                    <Icons width={30} height={30} name={'coinDicon'} />
                                    <Box>
                                        <Typography sx={{ fontSize: "14px", lineHeight: "18.2px", fontWeight: "600", color: "#282829" }}>{selectedTransaction.qutionsanswer} {t("transactions_popup.questions")}</Typography>
                                        <Typography sx={{ fontSize: "12px", lineHeight: "15.6px", fontWeight: "500", color: "#494A4B" }}>{t("transactions_popup.answered_correctly")}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ border: "1px solid #F1F2F3", borderRadius: "12px", padding: "14px 10px", fontSize: "12px", lineHeight: "16px", fontWeight: "500", color: "#282829" }}>
                                <span style={{ fontSize: "12px", lineHeight: "16px", fontWeight: "700", color: "#282829" }}>{t("transactions_popup.note")}: </span>{t("transactions_popup.earned_points_valid_till")} {selectedTransaction.date}
                            </Box>
                        </Box>
                    )}
                    {selectedtest2 && selectedtest2.type === "gifts" && !selectedTransaction && (
                        <Box sx={{ textAlign: "center" }}>
                            <Box sx={{ background: "#FFF0EA", borderRadius: "50%", width: "68px", height: "68px", display: "flex", alignItems: "center", justifyContent: "center", margin: "0 auto 12px" }}>
                                <Icons width={36.86} height={36.86} name={'ticketStar'} />
                            </Box>
                            <Typography sx={{ fontSize: "18px", lineHeight: "28px", fontWeight: "600", color: "#282829", marginBottom: "2px" }}>{selectedtest2.title}</Typography>
                            <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500", color: "#7D7E7F", marginBottom: "16px" }}>{selectedtest2.date}</Typography>
                            <Box sx={{ display: "flex", alignItems: "center", textAlign: "start", width: "100%", background: "#F9FBFC", padding: "10px", gap: "16px" }}>
                                <Box sx={{ width: "50%" }}>
                                    <Typography sx={{ fontSize: "12px", fontWeight: "700", lineHeight: "16px", color: selectedtest2.points > 0 ? "#2E844A" : "#B90022" }}>
                                        {selectedtest2.points > 0 ? `${selectedtest2.points} ${t("pts")}` : `${selectedtest2.points} ${t("pts")}`}
                                    </Typography>
                                    <Typography sx={{ fontSize: "12px", fontWeight: "500", lineHeight: "15.6px", color: "#494A4B" }}>
                                        {selectedtest2.points > 0 ? `Points Credited` : `Points Debited`}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: "50%" }}>
                                    <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "20px", color: "#DD7A00" }}>
                                        {selectedtest2.status}
                                    </Typography>
                                    <Typography sx={{ fontSize: "12px", fontWeight: "500", lineHeight: "15.6px", color: "#494A4B" }}>
                                        Transaction Status
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>

                <Button onClick={toggleDrawer(false)} sx={{
                    color: "#FFFFFF", fontSize: "14px", fontWeight: "500", lineHeight: "20px",
                    background: "#C8172F", margin: "16px 20px", borderRadius: "8px", textTransform: "initial",
                    '&:hover': { background: '#C8172F', color: '#FFF' }
                }}>
                    {t("okay_text")}
                </Button>
            </Drawer>
        </>
    );
};

export default PointsCards;
