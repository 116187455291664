import React, { useEffect } from 'react'
import { AuthContext } from '../context/AuthContext';
import { getBannerDetails, getsession, getTaskDetails, getTaskList } from '../utils/apis';
import { useLocation } from 'react-router-dom';
import { encodeUnicodeToBase64 } from '../utils/utilityfns';
import i18n from '../i18n';
import Bowser from 'bowser';

function Hidden() {

    const location = useLocation(); 
    const messageListener = (event:any) => {
      // Make sure to check the origin of the message for security
      // if (event.origin !== 'https://pmi-api.irepo.in') {
      //   return; // Ignore messages from unexpected origins
      // }

      const data = event.data;
      console.log('Message received from Salesforce:', data);

      // Perform actions based on the received event
      if (data.event === 'salesforceEvent') {
        console.log('Salesforce Event Data:', data.data);
        // Handle the event data (for example, display a message or update the state)
      }
    };

    // Add the event listener for postMessage
    
    window.addEventListener('message', messageListener);
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const userAgent = window.navigator.userAgent;
        const browser = Bowser.parse(window.navigator.userAgent)
        

        const params: any = {};
        queryParams.forEach((value, key) => {
          params[key] = value;
        });
        if (params["query"] && params["query"] !== null) {
          getsession(params["query"],userAgent)
            .then(async (result) => {
              console.log("resultresult", result,);
              const { token, data } = result;
              const accId = data?.User?.Contact?.AccountId || undefined;
              const city = data?.User?.Contact?.Account?.BillingAddress?.city || undefined;
              const contactId = data?.User?.ContactId || undefined;
              const lang = data?.User?.LanguageLocaleKey || undefined;
              window.parent.postMessage({ action: 'storeToLocal', label: city }, '*');

              if (token && accId) {
        try {
            await getTaskDetails(contactId,lang).then((res2) => {
                if (
                  res2.data &&
                  Array.isArray(res2.data) &&
                  res2.data.length > 0
                ) {
                    window.parent.postMessage({ action: 'currentActive', label: encodeUnicodeToBase64(JSON.stringify(res2.data[0])) }, '*');
                }
              });
            const data = await getTaskList(token,lang);
            console.log("data.active_tasks.tasks11",data.active_tasks.tasks)
            if (data && typeof data === "object") {
                console.log("data.active_tasks.tasks",data.active_tasks.tasks)
                window.parent.postMessage({ action: 'allActive', label: encodeUnicodeToBase64(JSON.stringify(data.active_tasks.tasks || [])) }, '*');
            } else {
                console.error("Data format is incorrect:", data);
            }
            const bannerData = await getBannerDetails({type:"banners",locations:[city]},token);
            console.log("data.active_tasks.tasks11",bannerData)
            if (data && typeof data === "object") {
                console.log("data.active_tasks.tasks",bannerData)
                window.parent.postMessage({ action: 'bannerData', label: encodeUnicodeToBase64(JSON.stringify(bannerData || [])) }, '*');
            } else {
                console.error("Data format is incorrect:", data);
            }
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/answer/fetch-notifications?type=notification`,
                {
                    method: "get",
                    headers: {
                        "Content-Type": "application/json",
                        'Accept-Language':i18n.language,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const notifcationData = await response.json();
            window.parent.postMessage({ action: 'notification', label:encodeUnicodeToBase64(JSON.stringify(notifcationData.data || []))}, '*');

            } catch (error) {
            console.error("Failed to fetch data:", error);
            }
              }
            })
            .catch((errr) => {
              console.log(errr);
            });
        }
        console.log('User-Agent:', userAgent);
        console.log('User-Agent:', browser);
        console.log("parama", params["query"],);
      }, [location]);
      
  return (
    <div style={{display:'none'}}>Hidden</div>
  )
}

export default Hidden