import i18n from "../i18n";
export const getResults = async (task_id: any, token: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/task/task-result/${task_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": i18n.language,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch task results");
    }

    const data = await response.json();
    console.log("Task Result:", data);
    return data.data;
  } catch (error) {
    console.error("Error fetching task results:", error);
    throw error;
  }
};

export const getsession = async (queryparams: any, userAgent?: any) => {
  return await fetch(
    `${
      process.env.REACT_APP_BASE_URL
    }/auth/get-session-data?encryptedData=${encodeURIComponent(queryparams)}`,
    {
      method: "get",
      headers: {
        "user-agent": userAgent,
      },
    }
  )
    .then((response) => response.json())
    .then((result: any) => {
      console.log("imgg", result);
      return result;
    })
    .catch((error) => {
      console.error(error);
      throw new Error(error?.message || "Something went wrong");
    });
};
export const getTaskDetails = async (accId: any, lang?: string) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}/task/fetch-tasks?account_id=${accId}`,
    {
      method: "GET",
      headers: {
        "Accept-Language": lang ? lang : i18n.language,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((result: any) => {
      console.log("imgg", result);
      return result;
    })
    .catch((error) => {
      console.error(error);
      throw new Error(error?.message || "Something went wrong");
    });
};
export const getBannerDetails = async (data: any, token: string) => {
  return await fetch(
    `${process.env.REACT_APP_BASE_URL}/barnd_banner/get_brand_banner_details_city`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Accept-Language": i18n.language,
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((result: any) => {
      console.log("imgg", result);
      return result;
    })
    .catch((error) => {
      console.error(error);
      throw new Error(error?.message || "Something went wrong");
    });
};

export const getTaskList = async (token: string, lang?: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/task/get-tasks-v1`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": lang ? lang : i18n.language,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch task results");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching task results:", error);
    throw error;
  }
};
export const getIRTaskResult = async (token: string, publish_id:string,lang?: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/task/get-ir-task-result/${publish_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": lang ? lang : i18n.language,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch task results");
    //  return {"data": [
    //     {
    //         "result_success": "",
    //         "result_failure": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/E5A94C8E-4746-4897-B41D-F36EC8E6C812_1.jpg",
    //             "image_validity": "Valid",
    //             "reason": null,
    //             "status": "fail"
    //         }
    //     },
    //     {
    //         "result_success": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/1ed5ac81-5285-49a9-8251-ff9121952bfb_1.jpg",
    //             "image_validity": "Valid",
    //             "reason": null,
    //             "status": "success"
    //         },
    //         "result_failure": ""
    //     },
    //     {
    //         "result_success": "",
    //         "result_failure": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/1e232e90-f635-469f-952a-8334d70fb851_1.jpg",
    //             "image_validity": "Valid",
    //             "reason": null,
    //             "status": "fail"
    //         }
    //     },
    //     {
    //         "result_success": "",
    //         "result_failure": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/0d0cf46d-084e-44bb-9f0a-e3a82898d8e9_1.jpg",
    //             "image_validity": "Invalid",
    //             "reason": "Blurred Image",
    //             "status": "fail"
    //         }
    //     },
    //     {
    //         "result_success": "",
    //         "result_failure": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/ec8a9dc2-0d07-4889-a71b-7e4ba0caca98_1.jpg",
    //             "image_validity": "Valid",
    //             "reason": null,
    //             "status": "fail"
    //         }
    //     },
    //     {
    //         "result_success": "",
    //         "result_failure": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/33d851f1-8d9c-468e-97ef-6875c086e5c6_1.jpg",
    //             "image_validity": "Invalid",
    //             "reason": "Blurred Image",
    //             "status": "fail"
    //         }
    //     },
    //     {
    //         "result_success": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/f23cb500-ebf3-4bff-b4a1-8a156265c82c_1.jpg",
    //             "image_validity": "Valid",
    //             "reason": null,
    //             "status": "success"
    //         },
    //         "result_failure": ""
    //     },
    //     {
    //         "result_success": "",
    //         "result_failure": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/5089E28F-109F-42BE-ACA1-12E6BD5F559E_1.jpg",
    //             "image_validity": "Valid",
    //             "reason": null,
    //             "status": "fail"
    //         }
    //     }
    // ]}
    }
    const data = await response.json();
    return data
    // return {
    //   "data": [
    //     {
    //         "result_success": "",
    //         "result_failure": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/E5A94C8E-4746-4897-B41D-F36EC8E6C812_1.jpg",
    //             "image_validity": "Valid",
    //             "reason": null,
    //             "status": "fail"
    //         }
    //     },
    //     {
    //         "result_success": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/1ed5ac81-5285-49a9-8251-ff9121952bfb_1.jpg",
    //             "image_validity": "Valid",
    //             "reason": null,
    //             "status": "success"
    //         },
    //         "result_failure": ""
    //     },
    //     {
    //         "result_success": "",
    //         "result_failure": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/1e232e90-f635-469f-952a-8334d70fb851_1.jpg",
    //             "image_validity": "Valid",
    //             "reason": null,
    //             "status": "fail"
    //         }
    //     },
    //     {
    //         "result_success": "",
    //         "result_failure": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/0d0cf46d-084e-44bb-9f0a-e3a82898d8e9_1.jpg",
    //             "image_validity": "Invalid",
    //             "reason": "Blurred Image",
    //             "status": "fail"
    //         }
    //     },
    //     {
    //         "result_success": "",
    //         "result_failure": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/ec8a9dc2-0d07-4889-a71b-7e4ba0caca98_1.jpg",
    //             "image_validity": "Valid",
    //             "reason": null,
    //             "status": "fail"
    //         }
    //     },
    //     {
    //         "result_success": "",
    //         "result_failure": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/33d851f1-8d9c-468e-97ef-6875c086e5c6_1.jpg",
    //             "image_validity": "Invalid",
    //             "reason": "Blurred Image",
    //             "status": "fail"
    //         }
    //     },
    //     {
    //         "result_success": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/f23cb500-ebf3-4bff-b4a1-8a156265c82c_1.jpg",
    //             "image_validity": "Valid",
    //             "reason": null,
    //             "status": "success"
    //         },
    //         "result_failure": ""
    //     },
    //     {
    //         "result_success": "",
    //         "result_failure": {
    //             "image_url": "https://storage.googleapis.com/pd-sw-prod-us-core-sw2/dist/test_images_modified/0fe55f6d-ef45-45af-9385-4e2e25dc56b2_169916/5089E28F-109F-42BE-ACA1-12E6BD5F559E_1.jpg",
    //             "image_validity": "Valid",
    //             "reason": null,
    //             "status": "fail"
    //         }
    //     }
    // ]
    // };
  } catch (error) {
    console.error("Error fetching task results:", error);
    throw error;
  }
};