import React, { useEffect, useState } from 'react'
import { getIRTaskResult, getsession } from '../utils/apis'
import Bowser from 'bowser';
import { Card, CardContent, CardMedia, Typography, Grid } from "@mui/material";
import { useLocation } from 'react-router-dom';

const ImageCardList = ({ data }:{data:Array<any>}) => {
  return (
    <Grid container spacing={2}>
      {data.map((item, index) => {
        // Extract success and failure results
        const success = item.result_success;
        const failure = item.result_failure;

        return (
          <React.Fragment key={index}>
            {/* Card for success */}
            {typeof (success)==="object" && <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={success.image_url}
                  alt="Success Image"
                />
                <CardContent>
                  <Typography variant="h6">Success</Typography>
                  <Typography variant="body2">
                    <strong>Validity:</strong> {success.image_validity}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Reason:</strong> {success.reason || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Status:</strong> {success.status}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>}

            {/* Card for failure */}
            {typeof (failure)==="object" &&<Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={failure.image_url}
                  alt="Failure Image"
                />
                <CardContent>
                  <Typography variant="h6">Failure</Typography>
                  <Typography variant="body2">
                    <strong>Validity:</strong> {failure.image_validity}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Reason:</strong> {failure.reason || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Status:</strong> {failure.status}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>}
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
function ViewIRResult() {
    const [IRResult, setIRResult] = useState<Array<any>|[]>([])
    const location = useLocation(); 

  useEffect(()=>{
    const queryParams = new URLSearchParams(location.search);
    const userAgent = window.navigator.userAgent;
    const browser = Bowser.parse(window.navigator.userAgent)
    

    const params: any = {};
    queryParams.forEach((value, key) => {
      params[key] = value;
    });
    if (params["query"] && params["query"] !== null) {
      getsession(params["query"],userAgent)
        .then(async (result) => {
          console.log("resultresult", result,);
          const { token, data } = result;
          const accId = data?.User?.Contact?.AccountId || undefined;
          const city = data?.User?.Contact?.Account?.BillingAddress?.city || undefined;
          const contactId = data?.User?.ContactId || undefined;
          const lang = data?.User?.LanguageLocaleKey || undefined;
          window.parent.postMessage({ action: 'storeToLocal', label: city }, '*');

          if (token && accId) {
            const data = await getIRTaskResult(token,params["publish_id"],lang);
            console.log("datata",data)
            setIRResult(data.data||[])
          }
        })
        
    }
  },[])
  return (
    <>
    <ImageCardList data={IRResult} />
    </>
  )
}

export default ViewIRResult