import React, { useEffect, useImperativeHandle, useState } from "react";
import { useDragLayer } from "react-dnd";
interface CustomDragLayerProps {
    isOver?: boolean;
  }
  
  interface CustomDragLayerRef {
    changeBackgroundColor: (color: string) => void;
  }
  
const CustomDragLayer = React.forwardRef<CustomDragLayerRef, CustomDragLayerProps>(
    ({ isOver }, ref) => {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getSourceClientOffset(),
  }));

  const [backgroundColor, setBackgroundColor] = useState<string>("#fff");

  // Expose a method to change the background color dynamically
  useImperativeHandle(ref, () => ({
    changeBackgroundColor: (color: string) => {
      setBackgroundColor(color);
    },
  }));

  if (!isDragging || !currentOffset) {
    return null;
  }


  const { x, y } = currentOffset;

  const style: React.CSSProperties = {
    position: "fixed",
    pointerEvents: "none",
    left: x,
    top: y,
    zIndex: 111,
    transform: "none", // Adjust to center the drag layer
  };

  const dragPreviewStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent:"space-between",
    width:"190px",
    gap: "8px",
    padding: "8px 12px",
    borderRadius: "8px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    background:backgroundColor
  };

  const circleStyle: React.CSSProperties = {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    background: item?.gradient || "linear-gradient(45deg, #6a11cb, #2575fc)",
  };

  const textStyle: React.CSSProperties = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#333",
  };

  return (
    <div style={style}>
      <div style={dragPreviewStyle}>
        <span style={textStyle}>{item?.name || "Dragging"}</span>
        <div style={circleStyle}></div>
      </div>
    </div>
  );
});

export default CustomDragLayer;
