import { Box, Button, Drawer, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import './TransactionsLists.scss';
import PointsCards from './pointsCards';
import Icons from '../Icons';
import { memo, useContext, useEffect, useState } from 'react';
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
// import RewardPoints from '../../pages/RewardPoints';
import i18n from "../../i18n";
import { useTranslation } from 'react-i18next';
interface Transaction {
    id:string,
    qutionsanswer:string
    title: string;
    date: string;
    status?: string;
    points: number;
    type:string
}

const TransactionsList = memo(({ setgainedvalue, settotalvalue, totalvalue, gainedvlue }: any) => {
    // const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState<string>('');
    const [selectedYear, setSelectedYear] = useState<string>('');
    // const [selectedgainedpoints, setSelectedgainedpoints] =  useState<number>(0);
    // const [selectedtotalspoints, setSelectedtotalspoints] = useState<number>(0);
    const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>([]);
    const [ordertaskdetails, setOrdertaskdetails] = useState<Transaction[]>([]);
    // const [ordertaskdetails2, setOrdertaskdetails2] = useState<Transaction[]>([]);
    const authContext = useContext(AuthContext);
    const { token }: any = authContext;
    const { t } = useTranslation();

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setIsOpen(open);
    };

    const handleMonthChange = (event: SelectChangeEvent<string>) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (year: string) => {
        setSelectedYear(year);
    };


    const fetchDropdownTaskNames = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/task/get_all_pacebook_details`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept-Language': i18n.language,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    year: selectedYear,
                    month: selectedMonth,
                }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();

            setgainedvalue(data.data.deductedPoints);
            settotalvalue(data.data.totalPoints);
            if(data.data.totalcount>0){
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/task/videotask-update-approval-status`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept-Language': i18n.language,
                        'Content-Type': 'application/json',
                    }
                });
            }
            const allDetails = data.data.combinedData.map((item: {
                type: string;
                id: number;
                status: string;
                title: string;
                timestamp: string;
                points: number;
            }) => ({
                id: item.id,
                title: item.title,
                date: item.timestamp,
                status: item.status,
                points: item.points,
                type:item.type ?? ''
            }));
    
         
            const approvedDetails = allDetails.filter((task: { status: string; }) => task.status === 'approved' ||'pending for approval' ||'inreview');
          
    
       
            setOrdertaskdetails(approvedDetails);
            
            setFilteredTransactions(allDetails);
        } catch (error) {
            console.error('Error fetching task details:', error);
         
        }
    };
    

    const applyFilter = () => {
        // const newFilteredTransactions = ordertaskdetails.filter((transaction) => {
        //     const transactionDate = new Date(transaction.date);
        //     const transactionYear = transactionDate.getFullYear().toString();
        //     const transactionMonth = transactionDate.toLocaleString('default', { month: 'long' });

        //     return (
        //         (selectedYear ? transactionYear === selectedYear : true) &&
        //         (selectedMonth ? transactionMonth === selectedMonth : true)
        //     );
        // });

        // setFilteredTransactions(newFilteredTransactions);
        setIsOpen(false);
        fetchDropdownTaskNames();
        
    };


    // useEffect(() => {
    //     console.log("aaaaa",totalvalue,gainedvlue,token)
    //     if(token && !totalvalue && !gainedvlue){
    //         fetchDropdownTaskNames();
    //     }
    //   }, [token,totalvalue,gainedvlue]);
      useEffect(() => {
        const debounce = setTimeout(() => {
            if (token && !totalvalue && !gainedvlue) {
                fetchDropdownTaskNames();
            }
        }, 300); // Delay for 300ms
    
        return () => clearTimeout(debounce);
    }, [token, totalvalue, gainedvlue]);



console.log(selectedYear, "selectedYearselectedYear")

   
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 5 }, (_, index) => (currentYear - index).toString());
// console.log(selectedtotalspoints, "selectedtotalspointsselectedtotalspoints")
// console.log(selectedgainedpoints, "selectedgainedpointsselectedgainedpoints")
//     useEffect(() => {
//         if (selectedtotalspoints !== 0) { 
//             navigate('/points-history', {
//                 state: { selectedgainedpoints, selectedtotalspoints }
//             });
//         }
//     }, [selectedgainedpoints, selectedtotalspoints, navigate]);

const calculateMonthDifference = (month: string): number => {
    const monthIndex = months.indexOf(month); 
    if (monthIndex === -1) return 0; 

    const currentMonth = new Date().getMonth(); 
    const currentYear = new Date().getFullYear();
    const selectedYearNumber = parseInt(selectedYear, 10); 


    const monthDifference = (currentYear - selectedYearNumber) * 12 + (currentMonth - monthIndex);
    return Math.max(monthDifference, 0);
};


    return (
        <>
     
            <div className='transactions-date'>
                <div className='transactions-div' style={{ maxWidth: "168px" }}>
                    <p className='transactions-text'>
                        {/* Transactions */}
                        {t("transactions")}
                    </p>
                    <p className='results'>
                        {ordertaskdetails.length} {t("results_from_last")} {selectedMonth ? calculateMonthDifference(selectedMonth) : '3'} {t("months")}
                    </p>
                </div>
                <Box onClick={toggleDrawer(true)} sx={{
                    cursor: "pointer",
                    border: '1px solid #E7E8E9', borderRadius: "8px", padding: "8px", display: "flex", alignItems: "center", gap: "6px", marginLeft: "auto"
                }}>
                    <Typography sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        lineHeight: "16px",
                        color: "#282829",
                    }}>
                        {!selectedYear || !selectedMonth ? `${t("filter_by_month")}` : `${selectedMonth.slice(0, 3)} ${selectedYear}`}
                    </Typography>
                    <Icons width={20} height={20} name={"arrowDown"} />
                </Box>
            </div>
            <PointsCards transactions={ordertaskdetails}/>
            <Drawer
                anchor="bottom"
                open={isOpen}
                onClose={toggleDrawer(false)}
                sx={{ ".MuiPaper-elevation": { borderRadius: "20px 20px 0 0", overflowY: "unset" } }}
            >
                <Box onClick={toggleDrawer(false)} sx={{
                    position: "absolute", top: "-20px", right: "50%",
                    transform: "translate(50% ,-50%)", cursor: "pointer"
                }}>
                    <Icons width={24} height={24} name={'closeIcon'} />
                </Box>
                <Box sx={{ padding: "20px 17px 0px" }}>
                    <Typography sx={{
                        color: "#282829", fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "24px", marginBottom: "16px", borderBottom: "1px solid #E7E8E9", paddingBottom: "10px"
                    }}>{t("filter_by_month")}</Typography>
                    <Typography sx={{
                        color: "#282829", fontSize: "12px",
                        fontWeight: "600",
                        lineHeight: "16px", marginBottom: "16px"
                    }}>{t("select_year")}</Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "14px", marginBottom: "16px" }}>
                        {years.map((year) => (
                            <Typography
                                key={year}
                                onClick={() => handleYearChange(year)}
                                sx={{
                                    color: selectedYear === year ? "#B90022" : "#494A4B",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    cursor: "pointer",
                                }}
                            >
                                {year}
                                
                            </Typography>
                        ))}
                    </Box>
                    <Typography sx={{
                        color: "#282829", fontSize: "12px",
                        fontWeight: "600",
                        lineHeight: "16px", marginBottom: "16px"
                    }}>{t("select_month")}</Typography>

                    <FormControl fullWidth>
                        <Select
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            displayEmpty
                            sx={{
                                color: "#282829", fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "20px",
                                outline: "none",
                                ".MuiSelect-select": {
                                    padding: "14px 8px"
                                    , borderRadius: "8px",
                                    border: "1px solid #E7E8E9",
                                    outline: "unset"
                                },
                            }}
                        >
                            <MenuItem value="" sx={{
                                color: "#282829", fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "20px",
                            }}>
                                <em>None</em>
                            </MenuItem>
                            {months.map((month) => (
                                <MenuItem key={month} value={month} sx={{
                                    color: "#282829", fontSize: "14px",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                }}>{month}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
              
                <Button onClick={()=>applyFilter()} disabled={!selectedYear || !selectedMonth} sx={{
                    color: "#FFFFFF", fontSize: "14px", fontWeight: "500", lineHeight: "20px",
                    background: "#C8172F", margin: "16px 20px", borderRadius: "8px", textTransform: "initial",
                    '&:hover': { background: '#C8172F', color: '#FFF' }, ":disabled": { background: "#C8172F", opacity: "0.5" }
                }}>
                    {t("apply_text")}
                </Button>
              
            </Drawer>
        </>
    );
});

export default TransactionsList;
