import React, { useContext, useEffect, useState } from "react";
import "./Points.scss";
import { Box, Typography } from "@mui/material";
import Icons from "../Icons";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";
import { getTaskList } from "../../utils/apis";

function Pnts({ pointsData }: any) {
  const { t } = useTranslation();
  const totalPoints = pointsData?.points?.total_points;
  const requiredApproval = pointsData?.result_details?.req_approval || false;
  const earnedPoints = pointsData?.points?.earned_points;
  const threshold = 0;
  // Show coin if earned points are less than or equal to 60% of total points
  const showCoin = earnedPoints===0 && totalPoints===0?true: earnedPoints > threshold;

  const isBonus = pointsData?.bonus_point > 0?true:false
  const [taskList, setTaskList] = useState({
    completed_tasks: [],
    active_tasks: [],
    upcoming_tasks: [],
  });

  const authContext = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      if (authContext) {
        const { token }: any = authContext;
        try {
          const data = await getTaskList(token);
          if (data && typeof data === "object") {
            setTaskList({
              completed_tasks: data.completed_tasks.tasks || [],
              active_tasks: data.active_tasks.tasks || [],
              upcoming_tasks: data.underreview_tasks.tasks || [],
            });
          } else {
            console.error("Data format is incorrect:", data);
          }
        } catch (error) {
          console.error("Failed to fetch data:", error);
        }
      }
    };
    fetchData();
  }, [authContext]);

  console.log('taskListtaskList', taskList)

  return requiredApproval ? (
    <Box
      sx={{
        background:
          isBonus ? "transparent" : "linear-gradient(179.88deg, rgba(255, 248, 187, 0) 21.44%, rgba(220, 220, 106, 0.32) 106.48%)", position: "relative",
        backgroundImage: isBonus ? "url(/images/Bg_results_diwali.png)" : "auto", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", paddingTop: isBonus ? "65px !important" : "auto",
      }}
      className="congrats"
    >
      {
        isBonus ? <Icons name={"coin_Mascot_diwali"} /> : <Icons name={"home"} />
      }
      {
        isBonus ? <Typography className="totalpoint2" variant="body2" sx={{ color: "#FFFFFF !important", maxWidth: "270px", margin: "0 auto !important", marginTop: "24px !important" }}>
          {t("diwali_points.congratulations_title")}
        </Typography> : <Typography className="totalpoint2" variant="body2">
          {t("taskdetaitls.availability.result.message")}
        </Typography>
      }

      <Box sx={{ margin: isBonus ? "12px !important" : 'auto' }}>
        <Typography className="status-type">
          {t("status_type.status_review")}
        </Typography>
        {
          pointsData?.bonus_point>0 ? <Typography className="message" color={"#FFFFFF !important"}>
            {/* {`${t("diwali_points.task_pts")}`} {pointsData?.points?.total_points || 0} */}
            {pointsData?.bonus_point>0  && (
              <>{`${t('diwali_points.bonus_pts_text', { task_points: pointsData.points?.total_points || 0,bonus_pts:pointsData?.bonus_point || 0 })}`} </>
            )}
          </Typography> : <Typography className="message">
            {/* {`${pointsData?.points?.total_points} ${t("taskdetaitls.availability.result.sub_message")}`} */}
            {`${t("taskdetaitls.availability.result.sub_message", { points: pointsData?.points?.total_points || 0 })}`}
            {/* {`${{t('earn_pnts.points_earned', { points: task.credit_points || 0 })}}`} */}
          </Typography>
        }
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        background:
          isBonus ? "transparent" : "linear-gradient(179.88deg, rgba(255, 248, 187, 0) 21.44%, rgba(220, 220, 106, 0.32) 106.48%)", position: "relative",
        backgroundImage: isBonus ? "url(/images/Bg_results_diwali.png)" : "auto", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat",
      }}
      className="congrats"
    >
      {showCoin ? isBonus ? <Icons name={"coin_Mascot_diwali"} /> : <Icons name={"home"} /> : <Icons name={"sadCoin"} />}
      <Typography className="totalpoint" color={"#7D7E7F"} variant="body2" sx={{ color: isBonus ? "#FFFFFF !important" : 'auto' }}>
        {(pointsData?.points.earned_points ===0 && pointsData?.points.total_points ===0)?
        t("taskdetaitls.educational.result.message_feedback")
        :showCoin
          ? t("taskdetaitls.educational.result.message_positive")
          : t("taskdetaitls.educational.result.message_negative")
          }
      </Typography>
     {(pointsData?.points.earned_points ===0 && pointsData?.points.total_points ===0) ?
     <Typography
      className="pts"
      sx={{ color: showCoin ? "#46C65A" : "#E68819",fontSize:"20px" }}
      variant="h1"
      >
         {t("taskdetaitls.educational.result.sub_message_feedback")}
        
    </Typography>
     :<Typography
        className="pts"
        sx={{ color: showCoin ? "#46C65A" : "#E68819" }}
        variant="h1"
      >
        {pointsData?.points.earned_points}
        <span style={{ fontSize: "32px" }}>/</span>
        <span style={{ fontSize: "24px" }}>
          {pointsData?.points?.total_points} pts
        </span>{" "}
      </Typography>}
      {(pointsData?.points.earned_points ===0 && pointsData?.points.total_points !==0) && <Box className="grey-flex">
        <Box className="card">
          <Icons name={"qaIcon"} width={34} height={34} />
          <Typography className="caption1" variant="caption1">
            <span>
              {pointsData?.result_details?.correct_answer}/
              {pointsData?.result_details?.total_questions_answered}
            </span>{" "}
            {t("taskdetaitls.educational.result.question_answered")}
          </Typography>
        </Box>
        <Box className="card">
          <Icons name={"qaIcon2"} width={34} height={34} />
          <Typography className="caption1" variant="caption2">
            <span>{pointsData?.total_earned_points}</span>
            {t("taskdetaitls.educational.result.point_balance")}
          </Typography>
        </Box>
      </Box>}
    </Box>
  );
}

export default Pnts;
