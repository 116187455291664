import { Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Icons from '../components/Icons';
import TransactionsList from '../components/poinstHistory';
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getsession } from '../utils/apis';
import { KEY_AUTH_DETAILS, KEY_BILLINGCITY, KEY_LANG } from '../utils/contants';
import i18n from '../i18n';
import { AuthContext } from '../context/AuthContext';

const RewardPoints = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    const [gainedvlue, setgainedvalue] = useState<number|null>(null);
    const [totalvalue, settotalvalue] = useState<number|null>(null);
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const params: any = {};
        queryParams.forEach((value, key) => {
            params[key] = value;
        });
        if (params["query"] && params["query"] !== null) {
            getsession(params["query"])
                .then(async (result) => {
                    console.log("resultresult", result,);
                    const { token, data } = result;
                    const accId = data?.User?.Contact?.AccountId || undefined;
                    const contactId = data?.User?.ContactId || undefined;
                    const city = data?.User?.Contact?.Account?.BillingAddress?.city || undefined;
                    const lang = data?.User?.LanguageLocaleKey || undefined;
                    if (token) {
                        authContext?.setSession(token);
                        localStorage.setItem(KEY_AUTH_DETAILS, accId);
                        localStorage.setItem(KEY_LANG, lang);
                        localStorage.setItem(KEY_BILLINGCITY, city);
                        i18n.changeLanguage(lang)
                    }
                })
                .catch((errr) => {
                    console.log(errr);
                });
        }
        console.log("parama", params["query"]);
    }, [location]);
// console.log(totalvalue, "totalvaluetotalvaluetotalvaluetotalvaluetotalvalue")
    return (
        <>
            <Box sx={{ background: "linear-gradient(180deg, #060000 0%, #53000F 80.13%);", padding: "13px 20px 19px" }}>
                <Box sx={{ boxShadow: "0px 0px 34px 5px #D5AC5C33", background: "rgba(213, 172, 92, 0.2)", borderRadius: "12px", marginBottom: "10px", display: "flex", padding: '18.5px 16px', width: "100%", alignItems: "center", }}>
                    <Box sx={{
                        display: "flex", alignItems: "center", textAlign: "start", gap: "16px", width: "50%",
                    }}>
                        <Icons width={33} height={33} name={'coinsSvgicon'} />
                        <Box>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "14px", fontWeight: "700", lineHeight: "20px" }} >{gainedvlue?`${gainedvlue} ${t("pts")}`:'0 pts'}</Typography>
                            <Typography sx={{ color: "#C5C6C7", fontSize: "12px", fontWeight: "400", lineHeight: "16px" }}>
                                {t("totol_points_earned")}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ background: "linear-gradient(117.62deg, #FAD466 11.17%, #947E3C 85.28%)", width: "1px", height: "32px", margin: "0px 20px 0 25px", }} />
                    <Box sx={{ display: "flex", alignItems: "center", textAlign: "start", gap: "8px", width: "50%" }}>
                        <Icons width={33} height={33} name={'coinsSvgicon'} />
                        <Box>
                            <Typography sx={{ color: "#FFFFFF", fontSize: "14px", fontWeight: "700", lineHeight: "20px" }}>{totalvalue?`${totalvalue} ${t("pts")}`:"0 pts"}</Typography>
                            <Typography sx={{ color: "#C5C6C7", fontSize: "12px", fontWeight: "400", lineHeight: "16px" }}>{t("total_redeemed")}</Typography>
                        </Box>
                    </Box>
                </Box>
                {/* {
                    !pointsExp ?
                        <Box sx={{
                            border: "1px solid #B90022", borderRadius: "8px", display: "flex", alignItems: "center", gap: "10px", justifyContent: "center", padding: "5px 0", backdropFilter: "blur(54px)",
                        }}>
                            <Icons width={26} height={23} name={'errorIcon'} />
                            <Typography sx={{ color: "#FFFFFF", fontSize: "12px", fontWeight: "600", lineHeight: "16px" }}>
                                200 Pts are expiring in 15 days
                            </Typography>
                        </Box> : <Typography sx={{ color: "#FFFFFF", fontSize: "12px", fontWeight: "500", lineHeight: "16px" }}>Points validity is 2 years from the date of credited!</Typography>
                } */}
            </Box>
            <TransactionsList 
                totalvalue={totalvalue}
                gainedvlue={gainedvlue}
                setgainedvalue={(va:any)=>setgainedvalue(va)}
                settotalvalue={(va:any)=>settotalvalue(va)}
            />
        </>
    );
};

export default RewardPoints;
