import React from 'react';
import DragDrop from '../components/CreateStick';

const BuildYourStickTask = () => {
    return (
        <>
            <DragDrop />
        </>
    );
};

export default BuildYourStickTask;
