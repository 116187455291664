import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";

import './brandSlick.scss'

// const brands = [
//     { image_path: '/images/BannerImggg.png', alt: "Amazon" },
//     { image_path: '/images/BannerImggg.png', alt: "Big Basket" },
//     { image_path: '/images/BannerImggg.png', alt: "Book My Show" },
// ];

interface BrandSlickProps {
    bannerSlickdata: any
}

const BrandSlick: React.FC<BrandSlickProps> = ({ bannerSlickdata }) => {
    const settings = {
        className: "BrandSlick-class",
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        adaptiveHeight: true
    };


    console.log('bannerSlickdata', bannerSlickdata)

    return (
        <div>
            <Slider {...settings}>
                {bannerSlickdata?.map((brand: any, index: any) => (
                    <div key={index}>
                        <Box
                            sx={{
                                margin: "0 6px",
                                textAlign: "center",
                            }}
                        >
                            <img
                                src={`${process.env.REACT_APP_BASE_URL}/uploads/${brand.image_path}`}
                                // src={`${process.env.REACT_APP_S3_BASE_URL}/${brand.image_path}`}
                                alt={brand.image_path}
                                crossOrigin="anonymous"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "8px",
                                    maxWidth: "335px"
                                }}
                            />
                        </Box>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default BrandSlick;
